.card {
  background-color: #272b36;
  color: #fff;
  padding: 0px;
}

.card .card-title {
  margin: 0px;
}

.card .card-body button {
  margin: 10px 0 0 0;
}

.card .card-body {
  padding: 12px 13px;
}
.card-img-top {
  height: 291px;
}

.row {
  margin: 0px;
}
