* {
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-weight: bold;
  /* border: 2px solid #fff; */
}

body{
  background-color: #0f1221;
  
}